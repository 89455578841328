import React from 'react';
import { Column, Container, Row } from '../../components/Grid';
import Hero from '../../components/Hero';
import Image from '../../components/Image';
import Layout from '../../components/Layout';
import OutboundLink from '../../components/OutboundLink';
import Section from '../../components/Section';
import homeBackground from '../../images/bg-repeat-x-y.gif';

const AboutPage = () => (
  <Layout title="About" style={{ backgroundImage: `url(${homeBackground})` }}>
    <Hero
      className="hero--about"
      iconFile="img-about-illustration.png"
      banner={true}
      heroBanner="About"
    >
      <h2>Cooperative Minds</h2>
      <p>
        Dig into the exciting ways that the agricultural cooperative (co-op)
        business model is connecting communities and inspiring everyday
        innovation nationwide. Created in partnership between CHS Foundation and
        Discovery Education, Cooperative Minds shows students firsthand
        how collaboration and creativity are combined in agricultural
        co-ops to nourish communities, overcome challenges, and grow success.
      </p>
    </Hero>
    <Section className="pb-2">
      <Container>
        <Row>
          <Column large={6}>
            <Image filename="img-about-celebrating.jpg" />
          </Column>
          <Column large={6}>
            <h2>Celebrating Community Development</h2>
            <p>
              Cooperative Minds is dedicated to empowering students, educators,
              and communities with no-cost learning resources designed for any
              learning environment. Give students from every background the
              skills to collaborate with likeminded individuals and accomplish
              goals together.
            </p>
          </Column>
        </Row>
        <Row>
          <h2>About the Partners </h2>
        </Row>
        <Row>
          <Column large={6}>
            <Image
              className="about__logos"
              filename="logo-chs.svg"
              style={{ maxHeight: '60px' }}
            />
            <p>
              The CHS Foundation is funded by charitable gifts from CHS Inc., a
              leading global agribusiness owned by farmers, ranchers and
              cooperatives. The CHS Foundation is focused on developing ag
              leaders for life. We are achieving our goals through strategic
              initiatives including advancing understanding of the ag
              cooperative business model, cultivating student success through
              university partnerships, and growing high-impact ag leadership
              programs. For more information on our programs, visit
              chsfoundation.org.
            </p>
          </Column>
          <Column large={6}>
            <Image className="about__logos" filename="logo-de.png" />
            <p>
              Discovery Education is the worldwide edtech leader whose
              state-of-the-art digital platform supports learning wherever it
              takes place. Through its award-winning multimedia content,
              instructional supports, and innovative classroom tools, Discovery
              Education helps educators deliver equitable learning experiences
              engaging all students and supporting higher academic achievement
              on a global scale. Discovery Education serves approximately 4.5
              million educators and 45 million students worldwide, and its
              resources are accessed in nearly 100 countries and territories.
              Inspired by the global media company Warner Bros. Discovery, Inc.,
              Discovery Education partners with districts, states, and trusted
              organizations to empower teachers with leading edtech solutions
              that support the success of all learners. Explore the future of
              education at{' '}
              <OutboundLink to="www.discoveryeducation.com">
                www.discoveryeducation.com
              </OutboundLink>
              .
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default AboutPage;
