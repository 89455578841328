import React from 'react';
import classNames from 'classnames';
import Image from '../Image';
import PropTypes from 'prop-types';
import { Container, Row, Column } from '../../components/Grid';
import './styles.scss';

const Hero = ({ heroBanner, banner, className, iconFile, children }) => {
  const getClassnames = () =>
    classNames('hero', banner && 'hero--banner', className);

  if (heroBanner && iconFile) {
    return (
      <div className={getClassnames()}>
        <Container className="hero__banner">
          <Row>
            <h1>{heroBanner}</h1>
          </Row>
        </Container>
        <Container className="hero__copy">
          <Row>
            <Column large={6}>{children}</Column>
            <Column large={6} className="hero__image">
              <Image filename={iconFile} />
            </Column>
          </Row>
        </Container>
      </div>
    );
  } else if (heroBanner) {
    return (
      <div className={getClassnames()}>
        <Container className="hero__banner">
          <Row>
            <h1>{heroBanner}</h1>
          </Row>
        </Container>
        <Container className="hero__copy">
          <Row>
            <Column large={6}>{children}</Column>
            <Column large={6} className="hero__image"></Column>
          </Row>
        </Container>
      </div>
    );
  } else {
    return (
      <div className={getClassnames()}>
        <Container>
          <Image
            filename="logo-cooperative-w-tagline.svg"
            className="hero__logo"
          />
          <div className="hero__blue-banner">
            <Image
              filename="img-home-bg-lead.svg"
              className="hero__plant-icon"
            />
          </div>
          <Image filename="img-home-lead.png" className="hero__group-image" />
        </Container>
      </div>
    );
  }
};

Hero.defaultProps = {
  heroBanner: false,
  className: null,
  children: false,
  banner: false,
  iconFile: null,
};

Hero.propTypes = {
  /** A hero banner headline copy */
  heroBanner: PropTypes.string,
  /** A custom class name */
  className: PropTypes.string,
  /** The inner hero copy */
  children: PropTypes.string,
  /** Add the banner hero */
  banner: PropTypes.bool,
  /** The icon file name */
  iconFile: PropTypes.string,
};

export default Hero;
